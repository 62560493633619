import { Box } from "@mui/material";
import React from "react";
import backgroundImage from "../../assets/img/1.webp";

import logo from "../../assets/logo/logo.svg";

const AuthLayout = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#000000",
          backgroundImage: `url('${backgroundImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          width:'100vw',
 
               boxSizing:'border-box'
      
        }}
      >
        <Box
          sx={{
            width: { lg: "70%", md: "70%", sm: "100%", xs: "100%" },
            backgroundColor: "#000000b7",
            display: "grid",
            placeItems: "center",
                 boxSizing:'border-box',
                        
          }}
        >
          <Box sx={{ width: {lg:'80%', md:'80%', sm:"90%", xs:'90%'} , boxSizing:'border-box'}}>
            <Box
              sx={{
                display: {lg:"none",md:'none', sm:'grid', xs:'grid'},
                placeItems: "center",
mb:7
              }}
            >
              <img src={logo} width={100}/>
            </Box>
            {children}
          </Box>
        </Box>
        <Box
          sx={{
            width: "30%",
            display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
            justifyContent: "center",
       
          }}
        >
          <Box sx={{ mt: 12 }}>
            <img src={logo} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AuthLayout;
